<template>
  <section class="events-landing">
    <router-link class="events-landing__item" to="/exhibitions">
      <img
        class="events-landing__item-image"
        src="@/assets/images/events/exhibitions.png"
        alt="Exhibitions"
      />
      <h2 class="events-landing__item-title text-title">Exhibitions</h2>
      <p class="events-landing__item-description text-description">
        A listing of browngrotta arts’ exhibitions at the gallery space and other venues.
      </p>
    </router-link>
    <router-link class="events-landing__item" to="/events/events">
      <img
        class="events-landing__item-image"
        src="@/assets/images/events/events.png"
        alt="Events"
      />
      <h2 class="events-landing__item-title text-title">Events</h2>
      <p class="events-landing__item-description text-description">
        Art fairs, book signings, webinars and other events in which browngrotta arts has
        participated.
      </p>
    </router-link>
  </section>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  components: {},

  data() {
    return {};
  },
  head: {
    title: function () {
      return {
        inner: "Events browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Events browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Events browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Events browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Events browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Events browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Events browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Events browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Events browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  created() {
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Events",
        to: "/events",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),
  },
};
</script>

<style lang="scss" scoped>
.events-landing {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 60rem));
  grid-column-gap: 2.8rem;
  grid-row-gap: 6.5rem;
  padding: 21rem 10.5rem 11rem;
  justify-content: center;
  @media screen and (max-width: $md) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: $xs) {
    grid-template-columns: 1fr;
    grid-row-gap: 7.5rem;
    padding: 21rem 2rem 11rem;
  }
  &__item {
    display: block;
    cursor: pointer;
    text-decoration: none;
    max-width: 60rem;
    &-image {
      width: 100%;
      max-width: 60rem;
      border-radius: 4px;
      @media screen and (max-width: $xxs) {
        width: 100%;
        height: 100vw;
      }
    }
    &-title {
      font-size: 4rem;
      line-height: 1.2;
      margin: 15px 0 2rem;
    }
  }
}
</style>
